'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.default = styler;
function styler(element) {
    function getElements() {
        if (element instanceof HTMLElement) {
            return [element];
        } else if (typeof element === 'string') {
            return document.querySelectorAll(element);
        }

        return [];
    }

    return {
        get: function get(styles) {
            if (!Array.isArray(styles)) {
                throw new Error('Second parameter of this function should be an array');
            }

            var elems = getElements();

            if (elems.length === 0) {
                return false;
            }

            var elem = elems[0];

            var obj = {};

            if (elem instanceof HTMLElement && styles) {
                styles.map(function (style) {
                    return obj[style] = window.getComputedStyle(elem, null).getPropertyValue(style);
                });
                return obj;
            }
        },
        set: function set(styles) {
            if ((typeof styles === 'undefined' ? 'undefined' : _typeof(styles)) !== 'object') {
                throw new Error('Second parameter of this function should be an object');
            }

            var elems = getElements();

            if (elems.length === 0) {
                return false;
            }

            elems.forEach(function (elem) {
                for (var i in styles) {
                    if (styles.hasOwnProperty(i)) {
                        elem.style[i] = styles[i];
                    }
                }
            });
        }
    };
}